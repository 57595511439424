import "core-js/modules/es.array.push.js";
import { baseURL } from '@/utils/config';
import Api from "@/utils/api";
import noTime from './noTime';
import rzxy from './rzxy.vue';
import zero from '../views/Order/zero';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  inject: ['reload'],
  components: {
    noTime,
    rzxy,
    zero
  },
  props: {
    istemporarily: {
      type: Boolean,
      default: false
    },
    isSOU: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rzxyShow: false,
      timeShow: false,
      isDJ: false,
      user: {
        name: '商超A',
        id: '030451'
      },
      id: null,
      isAdd: false,
      form: {},
      prisonList: JSON.parse(window.localStorage.getItem("prisonList")),
      prison: JSON.parse(window.localStorage.getItem("prison")),
      baseURL: baseURL,
      isRZ: false,
      list: [],
      type: '',
      msg: '',
      timer: null,
      info: {},
      zeroShow: false
    };
  },
  mounted() {
    this.user = JSON.parse(window.localStorage.getItem("user"));
    this.id = JSON.parse(window.localStorage.getItem("user")).id.slice(-8);
    this.getType();
    this.authStatus();
    this.getBusinessDetail1();
    let that = this;
    window.addEventListener('setItemEvent', function (e) {
      if (e.key === 'user') {
        // tableData 是需要监听的键名
        that.user = JSON.parse(e.newValue);
        that.$forceUpdate();
      }
    });
  },
  destroyed() {
    // clearInterval(this.timer)
    window.clearInterval(this.timer);
  },
  methods: {
    getType() {
      this.list = [];
      if (this.prison.innerStatus && this.prison.outsideStatus) {
        this.list = ['所内商城', '家属商城'];
      } else {
        if (this.prison.innerStatus) {
          this.list = ['所内商城'];
        }
        if (this.prison.outsideStatus) {
          this.list = ['家属商城'];
        }
      }
      let arr = this.list.filter(i => i == window.localStorage.getItem('type'));
      if (arr.length == 0) {
        this.type = this.list[0];
        window.localStorage.setItem('type', this.type);
      } else {
        this.type = window.localStorage.getItem('type');
      }
    },
    async zeroClose() {
      try {
        let data = {
          preferentialReadStatus: 1,
          storeId: this.user.id,
          prisonsNo: JSON.parse(window.localStorage.getItem('prison')).prisonsNo,
          outsideStatus: window.localStorage.getItem('type') == '所内商城' ? false : true
        };
        const res = await Api.modifyPreferentialReadStatus(data);
        this.zeroShow = false;
      } catch (error) {
        ElMessage.error(error.toString());
      }
    },
    see() {
      this.rzxyShow = true;
    },
    rzxyClose() {
      this.rzxyShow = false;
    },
    async getBusinessDetail() {
      try {
        let data = {
          storeId: this.user.id,
          prisonsNo: this.prison.prisonsNo,
          type: window.localStorage.getItem('type') == '所内商城' ? 0 : 1
        };
        const res = await Api.getBusinessDetail(data);
        this.info = res;
        if (res.frozenStatus === 1) {
          this.msg = res.frozenReason;
          this.isDJ = true;
        }
        let preferentialTime = this.info.preferentialTime;
        let day1 = this.$moment().format("YYYY-MM-DD HH:mm");
        if (!this.$moment(day1).isBefore(preferentialTime) && preferentialTime && !this.info.preferentialReadStatus) {
          this.zeroShow = true;
        }
        let expiryDate = this.info.expiryDate;
        let day = this.$moment().format("YYYY-MM-DD");
        if (!this.$moment(day).isBefore(expiryDate) && !this.info.expiryReadStatus) {
          this.timeShow = true;
        }
      } catch (error) {
        ElMessage.error(error.toString());
      }
    },
    async getBusinessDetail1() {
      try {
        let data = {
          storeId: this.user.id,
          prisonsNo: this.prison.prisonsNo,
          type: window.localStorage.getItem('type') == '所内商城' ? 0 : 1
        };
        console.log(data.type, '1111', window.localStorage.getItem('type'));
        const res = await Api.getBusinessDetail(data);
        this.info = res;
        this.setStatus(JSON.parse(window.localStorage.getItem("prison")));
      } catch (error) {
        ElMessage.error(error.toString());
      }
    },
    close() {
      this.isDJ = false;
    },
    async close1() {
      try {
        let data = {
          expiryReadStatus: 1,
          storeId: this.user.id,
          prisonsNo: JSON.parse(window.localStorage.getItem('prison')).prisonsNo,
          outsideStatus: window.localStorage.getItem('type') == '所内商城' ? false : true
        };
        const res = await Api.modifyPreferentialReadStatus(data);
        this.timeShow = false;
      } catch (error) {
        ElMessage.error(error.toString());
      }
    },
    // close1() {
    //     this.timeShow = false
    // },
    changeType() {
      if (this.list.length == 2) {
        this.type = this.type == '所内商城' ? '家属商城' : '所内商城';
        window.localStorage.setItem('type', this.type);
        // this.getBusinessDetail()
        this.$router.push('/');
        setTimeout(() => {
          this.$router.go(0);
        }, 500);
      }
    },
    getVale(v) {
      this.prison = v;
      window.localStorage.setItem('prison', JSON.stringify(v));
      this.getBusinessDetail1();
      // this.setStatus(v)
      this.$router.push('/');
      setTimeout(() => {
        this.$router.go(0);
      }, 500);
    },
    async getList() {
      try {
        const res = await Api.getUserPrisonList();
        this.setStatus(res[0]);
        window.localStorage.setItem('prison', JSON.stringify(res[0]));
        window.localStorage.setItem('prisonList', JSON.stringify(res));
        this.$forceUpdate();
      } catch (error) {
        ElMessage.error(error.toString());
      }
    },
    async getList1() {
      try {
        const res = await Api.getUserPrisonList();
        window.localStorage.setItem('prisonList', JSON.stringify(res));
      } catch (error) {
        ElMessage.error(error.toString());
      }
    },
    setStatus(v) {
      this.list = [];
      if (v.innerStatus && v.outsideStatus) {
        this.list = ['所内商城', '家属商城'];
      } else {
        if (v.innerStatus) {
          this.list = ['所内商城'];
        }
        if (v.outsideStatus) {
          this.list = ['家属商城'];
        }
      }
      let arr = this.list.filter(i => i == window.localStorage.getItem('type'));
      if (arr.length == 0) {
        this.type = this.list[0];
        window.localStorage.setItem('type', this.type);
      } else {
        this.type = window.localStorage.getItem('type');
      }
      window.localStorage.setItem('typeList', JSON.stringify(this.list));

      // if (!this.$moment(day).isBefore(preferentialTime)) {
      //     this.zeroShow = true
      // }
      this.getBusinessDetail();
    },
    combine(v) {
      if (!this.$moment(this.$moment().format("YYYY-MM-DD HH:mm")).isBefore(v)) {
        return false;
      } else {
        return true;
      }
    },
    cancel() {
      this.form = {};
      this.$refs.form.resetFields();
      this.isAdd = false;
      this.getList();
    },
    async save1() {
      try {
        const res = await Api.addPrisonByInvCode(this.form);
        this.cancel();
        ElMessage.success(res);
      } catch (error) {
        ElMessage.error(error.toString());
      }
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.save1();
        } else {
          return false;
        }
      });
    },
    add() {
      this.isAdd = true;
    },
    async out() {
      try {
        const res = await Api.out();
        window.localStorage.clear();
        // clearInterval(this.timer)
        window.clearInterval(this.timer);
        setTimeout(() => {
          this.$router.push("/login");
        }, 1000);
      } catch (error) {
        ElMessage.error(error.toString());
      }
    },
    async authStatus() {
      try {
        const res = await Api.authStatus();
        // if (res.authStatus === 0) {
        //     this.$router.push('/accreditation')
        // } else if (res.authStatus === 2) {
        //     this.$router.push({ path: "/accreditation", query: { step: 3 } });
        // } else if (res.authStatus === 5) {
        //     this.$router.push({ path: "/accreditation", query: { step: 3 } });
        // }
        this.isRZ = false;
        if (res.authStatus === 0 || res.authStatus === 2 || res.authStatus === 5) {
          this.$router.push('/accreditation');
        } else if (res.authStatus === 4) {
          this.isRZ = true;
          // this.timer = setInterval(() => {
          //     this.getBusinessDetail()
          // }, 60 * 1000);
          this.timer = window.setInterval(() => {
            this.getBusinessDetail();
          }, 60 * 1000);
        }
      } catch (error) {
        ElMessage.error(error.toString());
      }
    }
  }
};
import {
    createRouter,
    createWebHashHistory
} from 'vue-router'

import Layout from '../Layout/Layout.vue'
const routes = [
    {
        path: '/login',
        name: 'login',
        hidden: true,
        meta: { title: '商超中心管理系统-登录' },
        component: () => import('@/views/Login/index.vue')
    },
    {
        path: '/registered',
        name: 'registered',
        hidden: true,
        meta: { title: '商超中心管理系统-注册' },

        component: () => import('@/views/Registered/index.vue')
    },
    {
        path: '/changePassword',
        name: 'changePassword',
        meta: { title: '商超中心管理系统-忘记密码' },
        hidden: true,
        component: () => import('@/views/ChangePassword/index.vue')
    },
    {
        path: '/accreditation',
        name: 'accreditation',
        meta: { title: '商超中心管理系统-认证' },
        hidden: true,
        component: () => import('@/views/Accreditation/index.vue')
    },
    {
        path: '/doc/userDoc',
        name: 'userDoc',
        meta: { title: '商超中心管理系统-用户协议' },
        hidden: true,
        component: () => import('@/views/Doc/userDoc.vue')
    },
    {
        path: '/doc/index',
        name: 'doc',
        meta: { title: '商超中心管理系统-隐私政策' },
        hidden: true,
        component: () => import('@/views/Doc/index.vue')
    },
    {
        path: '/doc/user',
        name: 'user',
        meta: { title: '商超中心管理系统-用户操作手册' },
        hidden: true,
        component: () => import('@/views/Doc/user.vue')
    },
    {
        path: '/404',
        component: () => import('@/views/Error/404.vue'),
        name: 'NoFind',
        hidden: true,
    },
    {
        path: '/',
        component: Layout,
        redirect: '/home',
        meta: {
            title: '工作台',
            icon: 'icon-a-gongzuotai2x',
        },
        children: [
            {
                path: 'home',
                component: () => import('@/views/Home/index.vue'),
                name: '/home',
                meta: { title: '工作台', icon: 'icon-a-gongzuotai2x', affix: true },
            },
            {
                path: 'news',
                component: () => import('@/views/Home/news.vue'),
                name: '/news',
                hidden: true,
                meta: { title: '消息通知', },
            },

        ]
    },
    {
        path: '/product',
        component: Layout,
        redirect: '/product/index',
        meta: {
            title: '商品管理',
            icon: 'icon-a-weizhitiaozheng-daizhihang2x',
        },
        children: [
            {
                path: 'index',
                component: () => import('@/views/Product/index.vue'),
                name: '/product/index',
                meta: { title: '商品管理' },
            },

        ]
    },
    {
        path: '/order',
        component: Layout,
        redirect: '/order/index',
        meta: {
            title: '订单管理',
            icon: 'icon-a-114dingdandingdanmingxishouzhimingxi2x',
        },
        children: [
            {
                component: () => import('@/views/Order/index.vue'),
                path: 'index',
                name: '/order/index',
                meta: { title: '订单管理' },
            },
            {
                component: () => import('@/views/Order/details.vue'),
                hidden: true,
                path: 'details',
                name: '/order/details',
                meta: { title: '订单管理-订单详情' },
            },

        ]
    },
    {
        path: '/chargeback',
        component: Layout,
        redirect: '/chargeback/index',
        meta: {
            title: '退单管理',
            icon: 'icon-a-Returngoods2x',
        },
        children: [
            {
                component: () => import('@/views/Chargeback/index.vue'),
                path: 'index',
                name: '/chargeback/index',
                meta: { title: '退单管理' },
            },
            {
                component: () => import('@/views/Chargeback/detail.vue'),
                hidden: true,
                path: 'details',
                name: '/chargeback/details',
                meta: { title: '退单管理-查看详情' },
            },
        ]
    },
    {
        path: '/unlockingrecords',
        component: Layout,
        redirect: '/unlockingrecords/index',
        meta: {
            title: '解锁记录',
            icon: 'icon-a-Returngoods2x',
        },
        children: [
            {
                component: () => import('@/views/Unlockingrecords/index.vue'),
                path: 'index',
                name: '/unlockingrecords/index',
                meta: { title: '解锁记录' },
            },
            {
                component: () => import('@/views/Unlockingrecords/log.vue'),
                path: 'log',
                hidden: true,
                name: '/unlockingrecords/log',
                meta: { title: '解锁记录-查看详情' },
            },

        ]
    },
    {
        path: '/user',
        component: Layout,
        redirect: '/user/index',
        meta: {
            title: '用户中心',
            icon: 'icon-a-shangcheng112x',
        },
        children: [
            {
                component: () => import('@/views/User/index.vue'),
                path: 'index',
                name: '/user/index',
                meta: { title: '用户中心' },
            },

        ]
    },
    // {
    //     path: '/about',
    //     component: Layout,
    //     redirect: '/about/index',
    //     meta: {
    //         title: '关于平台',
    //         icon: 'icon-a-guanyu2x',
    //     },
    //     children: [
    //         {
    //             component: () => import('@/views/About/index.vue'),
    //             path: 'index',
    //             name: '/about/index',
    //             meta: { title: '关于平台' },
    //         },

    //     ]
    // },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// 全局守卫：登录拦截 本地没有存token,请重新登录
router.beforeEach((to, from, next) => {
    // 判断有没有登录
    if (!window.localStorage.getItem('token')) {
        if (to.name == "login" || to.name == "registered" || to.name == "changePassword" || to.name == 'doc' || to.name == 'userDoc' || to.name == 'user') {
            next();
        } else {
            next("/login")
        }
    } else {
        if (to.fullPath === '/') {
            next("/home")
        } else {
            next();
        }
    }
    if (to.meta.title) {
        if (to.name == 'doc' || to.name == 'userDoc' || to.name == 'user') {
            document.title = to.meta.title
        } else {
            document.title = '商超中心管理系统'
        }
        // document.title = to.meta.title
        // document.title = '商超中心管理系统'
    }
});

export default router


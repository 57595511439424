import sfgz from './sfgz';
export default {
  components: {
    sfgz
  },
  data() {
    return {
      sfgzShow: false
    };
  },
  props: {
    checked: {
      type: Boolean,
      default: true
    },
    rzxyShow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    choose() {
      this.$emit('choose');
    },
    cancel() {
      this.$emit('rzxyClose');
    },
    see() {
      this.sfgzShow = true;
    },
    sfgzClose() {
      this.sfgzShow = false;
    }
  }
};
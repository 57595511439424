import { http } from './http'
class Api {

  //登录
  static async login(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/login?_allow_anonymous=true`,
        data: val
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //注册
  static async register(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/store/register`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //登录
  static async out() {
    try {
      const { data } = await http({
        method: 'post',
        url: `/logout`,
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取登录验证码
  static async getCode() {
    try {
      const { data } = await http({
        method: 'get',
        url: `/validate/getCode`,
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取登录用户信息
  static async getUser() {
    try {
      const { data } = await http({
        method: 'get',
        url: `/user/current`,
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取监所下拉
  static async getPrisonList(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/prison/orgnizationTree`,
        params: val
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //短信发送验证码
  static async sendMessage(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/message/jyfMessage`,
        params: val
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //企业资质认证状态
  static async authStatus(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/store/authStatus`,
        params: val
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //注册
  static async submitAuth(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/store/submitAuth`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //修改密码
  static async forgetPwd(val) {
    try {
      const { data } = await http({
        method: 'put',
        url: `/store/forgetPwd`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取当前商家
  static async getcurrent(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/store/current`,
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  static async getCurrentWithAddress(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/store/getCurrentWithAddress`,
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //修改商超信息
  static async changeStore(val) {
    try {
      const { data } = await http({
        method: 'put',
        url: `/store`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取商品分类
  static async goodCategory(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/goodCategory`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取商品表格
  static async getGoodsList(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/storeGood/allDetail`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //商品上下架
  static async toOnline(val) {
    try {
      const { data } = await http({
        method: 'put',
        url: `/storeGood/toOnline`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //上传商品图片
  static async uploadGoodsImg(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/upload/goods`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //修改商品图片
  static async uploadImage(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/storeGood/uploadImage`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //上传商品
  static async storeGood(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/storeGood`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //新增供货监所
  static async addPrisonByInvCode(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/store/addPrisonByInvCode`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //订单表格
  static async getNoDeliveryOrder(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/shoppingNew/getNoDeliveryOrder`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //退货列表
  static async getOrderRefund(id) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/shoppingNew/getOrderRefund`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //unlock
  static async unlockInfo(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/unlock`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //解锁记录四个值
  static async unlockStatistic(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/unlockStatistic`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //unlock
  static async preUnlock(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/preUnlock`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //修改密码
  static async modifyPassword(val) {
    try {
      const { data } = await http({
        method: 'put',
        url: `/store/modifyPassword`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取商户监所列表
  static async getUserPrisonList() {
    try {
      const { data } = await http({
        method: 'get',
        url: `/store/getUserPrisonList`,
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //是否有订单商户未付钱
  static async checkLocking(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/checkLocking`,
        data: val
      })
      if (data.code === 0) {
        return data
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //上传付款凭证
  static async uploadReceipt(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/uploadReceipt`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //订单详情
  static async getNoDeliveryOrderDetail(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/shoppingNew/getNoDeliveryOrderDetail`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //订单导出
  static async exportOrder(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/export`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //退单详情
  static async getOrderDetails(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/shoppingNew/getOrderDetail`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //导出商品
  static async exportStoreGood(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/storeGood/exportStoreGood`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //商超发货
  static async deliveryByPrisonRoom(val) {
    try {
      const { data } = await http({
        method: 'put',
        url: `/shoppingNew/deliveryByPrisonRoom`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //商超子订单表格
  static async getChildDeliveryOrder(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/shoppingNew/getChildDeliveryOrder`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //商超子订单表格
  static async getorderTrend(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/workbench/orderTrend`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //商超子订单表格
  static async getsales(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/workbench/sales`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //商超子订单表格
  static async getsumMoney(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/workbench/sumMoney`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //商超子订单表格
  static async getstatistics(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/workbench/statistics`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //公告列表
  static async getBulletinList(id) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-bulletin/list`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //消息列表
  static async getNoticeList(id) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-notice/list`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //商家注销
  static async delStore() {
    try {
      const { data } = await http({
        method: 'delete',
        url: `/store/delete`,
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  
  //查询交易记录详情
  static async getOrderdetail(id) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/detail`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  //交易记录
  static async getStorePayPage(id) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/page`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //子订单号下拉
  static async getChildSn(id) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/getChildSn`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //查看回执附件
  static async getOrderFiles(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/getOrderFiles`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //查看交易记录流程
  static async getOrderFlow(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/getOrderFlow`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //订单退单
  static async orderRefundApplication (val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/orderRefund`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //结束订单
  static async orderConfirm (val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/orderConfirm`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //检测是否可退单
  static async canRefuse(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/canRefuse`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //读通知
  static async readNews(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-notice/read`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
   //读通知
   static async readNewsAll(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-notice/readAll`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取总订单号的下拉
  static async getDeliverNo(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/getDeliverNo`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //导出退单
  static async getRefundOrderExcel(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/shoppingNew/getRefundOrderExcel`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //判断是看守所否冻结,获取优惠汇率
  static async getBusinessDetail(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/business/store/detail`,
        params: val,
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
   //退货确认
   static async orderRefundConfirm (val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/shoppingNew/orderRefundConfirm`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
//优惠汇率已读
static async changeYHHL (val) {
  try {
    const { data } = await http({
      method: 'put',
      url: `/business/store`,
      data: val
    })
    if (data.code === 0) {
      return data.response
    }
  } catch (error) {
    throw new Error(error)
  }
}
static async modifyPreferentialReadStatus (val) {
  try {
    const { data } = await http({
      method: 'post',
      url: `/business/store/modifyPreferentialReadStatus`,
      data: val
    })
    if (data.code === 0) {
      return data.response
    }
  } catch (error) {
    throw new Error(error)
  }
}
  //获取系统文档详情
  static async getDocDetail(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/s-doc/detail`,
        params: val,
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
//修改账户名称
  static async modifyAccount (val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/store/modifyAccount`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //修改手机号码
  static async modifyBindPhone (val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/store/modifyBindPhone`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
}


export default Api
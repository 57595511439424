import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7f9e9a20"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "Layout"
};
const _hoisted_2 = {
  class: "layoutContent"
};
const _hoisted_3 = {
  class: "left-nav"
};
const _hoisted_4 = {
  class: "right"
};
const _hoisted_5 = {
  class: "router-tab"
};
const _hoisted_6 = {
  class: "p"
};
const _hoisted_7 = {
  class: "routerBox"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_top = _resolveComponent("top");
  const _component_sideBarItem = _resolveComponent("sideBarItem");
  const _component_el_menu = _resolveComponent("el-menu");
  const _component_TagsView = _resolveComponent("TagsView");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_top, {
    isSOU: true
  }), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_menu, {
    "default-active": $options.defaultActive,
    class: "el-menu-vertical menu",
    "background-color": "#292E4C",
    "text-color": "#F9F9F9",
    "active-text-color": "#3776FF",
    uniqueOpened: true,
    router: true
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.menuList, route => {
      return _openBlock(), _createBlock(_component_sideBarItem, {
        key: route.path,
        index: route.path,
        item: route,
        "base-path": route.path
      }, null, 8, ["index", "item", "base-path"]);
    }), 128))]),
    _: 1
  }, 8, ["default-active"])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_TagsView, {
    class: "tagView"
  }), _createElementVNode("p", _hoisted_6, _toDisplayString($data.time), 1)]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_router_view)])])])]);
}
export default {
  props: {
    checked: {
      type: Boolean,
      default: true
    },
    sfgzShow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    sfgzClose() {
      this.$emit('sfgzClose');
    }
  }
};
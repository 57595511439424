import { createApp } from 'vue'
import App from './App.vue'


import router from './router/index'
import store from './store'
import 'element-plus/es/components/message/style/css'
import 'element-plus/theme-chalk/el-loading.css'
import './assets/css/style.css'
import './assets/font/font.css'
import './assets/css/common.less'
import './assets/iconfont/iconfont.css'
import './assets/iconfont/iconfont-weapp-icon.css'
import dispatchEventStroage from './utils/watchLocalStorage'

import moment from 'moment'

const app = createApp(App)
app.config.globalProperties.$moment = moment
moment.locale('zh-cn')

app.use(router).use(store).use(dispatchEventStroage).mount('#app')
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}
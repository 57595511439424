export default {
  props: ['zeroShow', 'info'],
  data() {
    return {
      // info:JSON.parse(window.localStorage.getItem('prison'))
    };
  },
  methods: {
    sumbit() {
      this.$emit('zeroClose');
    }
  }
};
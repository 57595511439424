import { defineComponent } from "vue";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
export default defineComponent({
  components: {
    ElConfigProvider
  },
  name: 'App',
  provide() {
    return {
      reload: this.reload
    };
  },
  setup() {
    return {
      locale: zhCn,
      isRouterAlive: true
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    }
  }
});
import Item from './Item';
export default {
  name: 'SidebarItem',
  components: {
    Item
  },
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  methods: {
    getClass(name) {
      let index1 = this.$route.name.lastIndexOf('/');
      let index2 = this.$route.name.indexOf('/');
      let index3 = name.lastIndexOf('/');
      let index4 = name.indexOf('/');
      if (this.$route.name.substring(index2, index1) == name.substring(index4, index3)) {
        return true;
      } else {
        return false;
      }
    },
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = {
          ...parent,
          path: '',
          noShowingChildren: true
        };
        return true;
      }
      return false;
    }
  }
};
export default {
  props: ['timeShow', 'type'],
  data() {
    return {
      prison: JSON.parse(window.localStorage.getItem('prison'))
    };
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};